<template>
  <AddTestRunView
    v-if="showRuns" 
    v-model="selectedRuns"
    :backTitle="$t('plans.create.backToRerunPlan')"
    @back="handleBackClick"
    @onFilterChange="tableFilter = $event"
  >
    <template v-slot:action>
      <div class="action-btn-wrapper pa-3">
        <div class="d-flex">
          <v-btn
            class="font-inter text-capitalize fw-semibold text-theme-label rounded-lg"
            height="40px"
            depressed
            color="#F2F4F7"
            v-if="tableFilter === 'all'"
            @click="handleAddTestRuns"
          >
            {{ $t('plans.createTestRun') }}
          </v-btn>

          <v-menu
            v-model="menuOpen"
            :close-on-content-click="false"
            offset-y
            top
            right
            v-if="tableFilter === 'selected'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                depressed
                background-color="#F2F4F7"
                class="font-inter text-capitalize rounded-lg fw-semibold text-theme-label"
                height="38px"
                v-on="on"
              >
                {{ $t('actions') }} <v-icon>{{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </template>

            <v-list class="actions-list font-inter">
              <v-list-item
                class="actions-item text-left"
                @click="handleAddTestRuns"
              >
                <v-list-item-title>{{ $t('plans.createTestRun') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                class="actions-item text-left"
              >
                <v-list-item-title>{{ $t('plans.create.testRuns.addConfiguration') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            class="font-inter text-capitalize fw-semibold rounded-lg white--text ml-3"
            height="40px"
            depressed
            color="blue"
            :loading="loadingDuplicate"
            @click="duplicatePlan"
            v-if="selectedRuns.length > 0"
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </div>

    </template>
  </AddTestRunView>
  <div
    v-else
    class="test-plan-duplicate font-inter pt-3"
    fluid
  >
    <PlanHeader
      :is-duplicate="PlanViewType === 'Duplicate'"
      :title="$t('plans.title')"
      :action-text="$t('plans.createTestPlan')"
      :active_label="$t('plans.active')"
      :archived_label="$t('plans.archived')"
      :btn_show="true"
      :filter="filter"
      :active-item-count="activeItemCount"
      :archived-item-count="archivedItemCount"
      @update-filter="updateFilter"
    />
    <Loader v-if="loaderState" />
    <template v-else-if="activeItemCount == 0 && !isFilter && filter != 'archived' && !tableLoadingState">
      <div class="mt-3 mb-0 white rounded-lg mx-0 project-placeholder-height d-flex justify-center align-center">
        <ActiveEmptyState
          :image-src="require('@/assets/png/test-plans-placeholder.png')"
          :title="$t('plans.placeholder.title')"
          :description="$t('plans.placeholder.description')"
          :button-text="$t('plans.createTestPlan')"
          :button-route="{ name: 'TestPlanCreate' }"
          button-color="primary"
        >
          <template #description>
            <p class="mb-0 mt-3">
              {{ $t('plans.placeholder.description.part1') }}
            </p>
            <p class="mb-0">
              {{ $t('plans.placeholder.description.part2') }}
            </p>
            <p class="mb-0">
              {{ $t('plans.placeholder.description.part3') }}
            </p>
          </template>
        </ActiveEmptyState>
      </div>
    </template>
    <template v-else-if="archivedItemCount == 0 && !isFilter && filter != 'ongoing' && !tableLoadingState">
      <div class="mt-3 mb-0 white rounded-lg mx-0 project-placeholder-height d-flex justify-center align-center">
        <ArchivedEmptyState
          :image-src="require('@/assets/png/test-plans-placeholder.png')"
          :title="$t('plans.archived_empty_state.title')"
        >
          <template #description>
            <p class="mb-0 mt-3">
              {{ $t('plans.archived_empty_state.description.part1') }}
            </p>
            <p class="mb-0">
              {{ $t('plans.archived_empty_state.description.part2') }}
            </p>
          </template>
        </ArchivedEmptyState>
      </div>
    </template>
    <v-card
      v-else
      class="py-6 px-6 app-height-global"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <div class="d-flex flex-row justify-space-between align-center">
        <div class="d-flex flex-row justify-start align-center">
          <v-responsive
            class="ma-0"
            max-width="344"
          >
            <v-text-field
              v-model="searchFilter"
              :placeholder="$t('searchByName')"
              background-color="#F9F9FB"
              class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
              height="40"
              dense
              hide-details
            >
              <template v-slot:prepend-inner>
                <SearchIcon />
              </template>
            </v-text-field>
          </v-responsive>
          <PlanFilter 
            :configurations="configurations" 
            :milestones="activeMilestones"  
            :tags="tags"
            @applyFilters="applyFilters"
          />
        </div>
      
        <SettingsMenu 
          table-type="plansDuplicate" 
        />
      </div>
      <test-plans-table
        :filtered-headers="filteredHeaders"
        :filtered-items="filteredPlans"
        :item-key="itemKey"
        :row-class="rowClass"
        :clear-selection="clearSelection"
        :single-select="true"
        @edit-item="editItem"
        @select-row="handleRowClick"
        @select-item="setselected"
        @archive-item="confirmArchiveTestPlan"
        @unarchive-item="confirmUnArchiveTestPlan"
        @delete-item="confirmDeletePlan"
        @add-to-milestone="handleAddToMilestione"
      />
      <div 
        v-if="hasSelectedItems"
        class="action-btn-wrapper pa-3"
      >
        <v-btn
          class="text-capitalize rounded-lg fw-semibold white--text"
          height="40px"
          depressed
          color="blue"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
          @click="handleDuplicate"
        >
          {{ $t('testruns.rerun') }}
        </v-btn>
      </div>
    </v-card>
    <plans-list-filter
      :open="isOpenFilter"
      @filters="applyFilters"
      @close="isOpenFilter = false"
    />
    <PlanAddToMilestone
      v-if="showAddToMilestoneDialog"
      :test-plan="selectedPlan"
      :selected-plans="selecteditems"
      :show-dialog="showAddToMilestoneDialog"
      @change-milestone-drawer-state="toggleMilestoneDrawer"
    />
    <PlanDiscardDialog
      v-model="showConfirmDialog"
      :title="dialog.confirmDialogTitle"
      :content="dialog.confirmDialogContent"
      :content_part2="dialog.confirmDialogContentPartTwo"
      :run_name="dialog.confirmDialogName"
      :btn_label="dialog.confirmDialogBtnLabel"
      :color="dialog.confirmDialogBtnColor"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmBtnClick(dialog.confirmType)"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, createNamespacedHelpers, mapActions } from 'vuex';

import PlanFilter from '@/components/TestPlans/PlanFilter.vue';
import PlansListFilter from '@/views/Tests/Plans/Components/List/PlansListFilter';
import PlanDiscardDialog from '@/components/TestPlans/PlanDiscardDialog.vue';
import TestPlansTable from '@/components/TestPlans/TestPlansTable.vue';
import PlanHeader from '@/components/TestPlans/PlanHeader.vue';
import PlanAddToMilestone from '@/components/TestPlans/PlanAddToMilestone.vue';
import makeMilestonesService from '@/services/api/milestone'
import makePlanService from '@/services/api/plan'
import AddTestRunView from '@/views/Tests/Plans/AddTestRunView.vue'
import makeConfigurationService from '@/services/api/configuration' 
import makeTagService from '@/services/api/tag';

import Loader from '@/components/base/Loader.vue';
import ActiveEmptyState from '@/components/base/ActiveEmptyState.vue';
import ArchivedEmptyState from '@/components/base/ArchivedEmptyState.vue';

import SearchIcon from '@/assets/svg/search-icon.svg';
import { sleep } from '@/utils/util';
import { showErrorToast, showSuccessToast } from '@/utils/toast';
import { runStateMap } from '@/constants/grid';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import { formatDate } from '@/utils/util';

export default {
  components: {
    PlanFilter,
    PlansListFilter,
    TestPlansTable,
    SearchIcon,
    PlanHeader,
    ActiveEmptyState,
    ArchivedEmptyState,
    Loader,
    SettingsMenu,
    AddTestRunView,
    PlanDiscardDialog,
    PlanAddToMilestone
  },
  data() {
    return {
      searchFilter: '',
      filter: 'ongoing',
      tab: 'ongoing',
      PlanViewType: 'Duplicate',
      loaderState: false,
      tableLoadingState: false,
      testPlans: [],
      isFilter: false,
      itemKey: 'uid',
      rowClass: () => 'test-plan-item',
      clearSelection: false,
      isOpenFilter: false,
      headers: [],
      isColumnFilter: false,
      milestones: [],
      tableFilter: 'all',
      menuOpen: false,
      configurations: [],
      tags: [],
      showConfirmDialog: false,
      showAddToMilestoneDialog: false,
      dialog: {
        confirmDialogTitle: '',
        confirmDialogContent: '',
        confirmDialogContentPartTwo: '',
        confirmDialogBtnLabel: '',
        confirmDialogBtnColor: 'primary',
        confirmDialogName: '',
        confirmType: '',
      },
      selecteditems: [],
      showRuns: false,
      loadingDuplicate: false,
      runUids: []
    };
  },
  computed: {
    ...mapGetters({
      currentOrg: 'user/currentAccount',
      dynamicHeaders:'headers/dynamicHeaders'
    }),
    selectedRuns:{
      get(){
        return this.runUids.length ? this.runUids : (this.selecteditems[0]?.runs ?? []);
      },
      set(value){
        this.runUids = value;
      }
    },
    filteredHeaders() {
      const filtered = this.headers.filter((x) => x.isSelected);
      return filtered;
    },
    activeItemCount() {
      return this.testPlans.filter((item) => item?.archivedAt === null).length;
    },
    archivedItemCount() {
      return this.testPlans.filter((item) => item?.archivedAt !== null).length;
    },
    filteredPlans() {
      let filtered = this.testPlansFilteredType;
      if (this.searchFilter) {
        filtered = filtered.filter((item) => this.matchesFilter(item));
      }
      if (this.isFilter && this.appliedFilters) {
        filtered = filtered.filter((item) => this.matchApplyFilter(item));
      }
      return filtered;
    },
    testPlansFilteredType() {
      let filtered = this.testPlans.filter((item) => ((item.archivedAt !== null) === (this.filter === 'ongoing' ? false : true)));
      return filtered.map((item) => {
        const createdFormat = new Date(item.createdAt);
        const createdAt = formatDate(createdFormat, 'MM/dd/yy');
        return {
          ...item,
          progress: this.staticValues(item, runStateMap, 'New'),
          createdAt
        }
      });
    },
    hasSelectedItems() {
      return this.selecteditems.length > 0;
    },
    activeMilestones() {
      return this.milestones.filter((milestone) => milestone.archivedAt === null && milestone.deletedAt === null);
    },
  },
  created() {
    if(!this.dynamicHeaders.plansDuplicate) {
      this.initializeHeaders({ type: 'plansDuplicate' });
    }
    this.headers = this.dynamicHeaders.plansDuplicate;
  },
  mounted() {
    if(this.$route.params.plan) {
      this.showRuns = true
      this.selecteditems = [this.$route.params.plan];
      this.selectedRuns = this.$route.params.plan.runs;
    }
    this.init();
  },
  methods: {
    ...mapActions( 'plan', 
    [
      'getAllTestPlans',
      'deleteTestPlan',
      'deleteTestPlans',
      'updateTestPlan',
      'updateTestPlans',]
    ),
    async init() {
      this.loaderState = false;
      this.tableLoadingState = true;
      let loaderStateTimeout = null;

      loaderStateTimeout = setTimeout(() => {
        this.loaderState = true;
      }, 1000);
      try {
        await Promise.all([
          this.getTestPlans(),
          this.getMilestones(),
          this.getConfigurations(),
          this.getTags()
        ]);
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'plans' });
      } finally {
        clearTimeout(loaderStateTimeout); 
        const hideLoader = async () => {

          await sleep(1000); 
          this.loaderState = false; 
          this.tableLoadingState = false;
        };
        hideLoader(); 
      }
    },
    ...mapActions("headers", ['initializeHeaders']),
    async getTestPlans() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const testPlanService = makePlanService(this.$api);
      try {
        const response = await testPlanService.getAllTestPlans(handle, projectKey, 1000, 0);
        this.testPlans = response.data?.plans;
        return response.data?.plans;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'test plans' });
        return [];
      }
    },
    async getMilestones() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const milestoneService = makeMilestonesService(this.$api);
      try {
        const response = await milestoneService.getMilestones(handle, projectKey);
        this.milestones = response.data?.milestones;
        return response.data?.milestones;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'milestones' });
        return [];
      }
    },
    async getConfigurations() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const configurationService = makeConfigurationService(this.$api);
      try {
        const response = await configurationService.getConfigurations(handle, projectKey, 10, 0);
        this.configurations = response.data?.configurations;
        return response.data?.configurations;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'configurations' });
        return [];
      }
    },
    async getTags() {
      const handle = this.$route.params.handle;
      const tagService = makeTagService(this.$api);
      try {
          const response = await tagService.getTags(handle);
          this.tags = response.data
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'tags' });
        return [];
      }
    },
    staticValues(item, obj, defaultValue) {
      return obj[item.customFields?.state] || obj[defaultValue];
    },
    updateFilter(newFilter) {
      this.filter = newFilter;
    },
    handleTab(value) {
      this.tab = value;
    },
    handleBackClick() {
      if(this.$route.params.plan) {
        this.$router.back();
      } else {
        this.showRuns = false
      }
    },
    handleAddTestRuns() {
      this.$router.push({
        name: 'TestRunCreate',
        params: {
        handle: this.$route.params.handle,
        key: this.$route.params.key
        },
      });
    },
    handleClickItem(item) {
      this.$router.push({ name: 'TestPlanRerun', params: { handle: this.$route.params.handle, key: this.$route.params.key, planId: item.id } });
    },
    handleDuplicate() {
      this.showRuns = true
      this.selectedRuns = this.selecteditems[0].runs;
    },
    async duplicatePlan(){
      this.loadingDuplicate = true;
      const planUid = this.selecteditems[0].uid;
      const runUids = this.selectedRuns?.map(item => item.uid)
      const payload = {
        planUid,
        runUids
      }
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const testPlanService = makePlanService(this.$api);

      await testPlanService.duplicateTestPlan(handle, projectKey, payload).then(() => {
        showSuccessToast(this.$swal, this.$t('success.planCreated', {item: this.selecteditems[0].name}))
        this.$router.push({name: 'TestPlans'})
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('toast.createError', {item: this.selecteditems[0].name}))
      })
      this.loadingDuplicate = false;
      
    },
    toggleMilestoneDrawer(newState) {
        this.showAddToMilestoneDialog = newState
    },
    async refreshData() {
      this.getTestPlans();
    },
    editItem(item) {
      this.selectedPlan = item;
      this.$router.push({
        name: 'TestPlanDetail',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          planId: item.uid
        },
      });
    },
    handleRowClick(item) {
      this.$router.push({
        name: 'TestPlanRerun',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key,
          planId: item.uid,
        },
      });
    },
    applyFilters(filters) {
      if (filters) {
        this.appliedFilters = filters;
        this.isFilter = true;
      } else {
        this.isFilter = false;
      }
      this.refreshData()
    },
    setselected(selecteditems) {
      this.clearSelection = false
      this.selecteditems = selecteditems
    },
    confirmArchiveTestPlan(item) {
      this.selectedPlan = item;
      this.handleConfirmDialog('archive')
    },
    confirmUnArchiveTestPlan(item) {
      this.selectedPlan = item;
      this.handleConfirmDialog('unarchive')
    },
    confirmDeletePlan(item) {
      this.selectedPlan = item;
      this.handleConfirmDialog('delete')
    },
    handleConfirmOnArchiveClick(status) {
      const payload = {
        archived: status,
      };
      const successMessage = status
        ? this.$t('toast.archiveSuccess', { item: this.$t('plans.title_single') })
        : this.$t('toast.unarchiveSuccess', { item: this.$t('plans.title_single') });

      const errorMessage = status
        ? this.$t('toast.archiveError', { item: this.$t('plans.title_single') })
        : this.$t('toast.unarchiveError', { item: this.$t('plans.title_single') });

      this.updateTestPlan({
        swal: this.$swal,
        handle: this.$route.params.handle,
        uid: this.selectedPlan.uid,
        projectKey: this.$route.params.key,
        payload: payload,
      }).then(() => {
        showSuccessToast(this.$swal, successMessage);
        this.refreshData()
      }).catch(error => {
        showErrorToast(this.$swal, errorMessage);
      });
      this.handleCloseClick()
    },
    handleAddToMilestione(item) {
      this.selectedPlan = item
      this.showAddToMilestoneDialog = true;
    },
    handleConfirmBtnClick(type) {
      switch (type) {
        case 'multi_archive':
          this.handleConfirmArchiveClick(true);
          break;
        case 'multi_unarchive':
          this.handleConfirmArchiveClick(false);
          break;
        case 'multi_delete':
          this.handleConfirmDeleteMultiClick();
          break;
        case 'archive':
          this.handleConfirmOnArchiveClick(true);
          break;
        case 'unarchive':
          this.handleConfirmOnArchiveClick(false);
          break;
        case 'delete':
          this.handleConfirmDeleteClick();
          break;
      }
    },
    async removePlan(handle, projectKey, uid) {
      const planService = makePlanService(this.$api);
      try {
        await planService.deleteTestPlan(handle, projectKey, uid);
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    async handleConfirmDeleteClick() {
      try {
        await this.removePlan(this.currentAccount.handle, this.$route.params.key, this.selectedPlan.uid);
        showSuccessToast(this.$swal, this.$t("plans.delete1TestPlan"));
        await this.refreshData();
      } catch (error) {
        showErrorToast(this.$swal, this.$t("plans.delete1TestPlan"));
      } finally {
        this.handleCloseClick();
      }
    },
    handleConfirmArchiveClick(status) {
      let plans = []
      this.selecteditems.map((item) => {
        let plan = {
          id: item.uid,
          archived: status
        };
        plans = [...plans, plan]
      });

      const successMessage = status
        ? this.$t('toast.archiveSuccess', { item: this.$t('plans.title') })
        : this.$t('toast.unarchiveSuccess', { item: this.$t('plans.title') });

      const errorMessage = status
        ? this.$t('toast.archiveError', { item: this.$t('plans.title') })
        : this.$t('toast.unarchiveError', { item: this.$t('plans.title') });

      this.updateTestPlans({
        swal: this.$swal,
        handle: this.currentAccount.handle,
        uid: this.selectedPlan.uid,
        projectKey: this.$route.params.key,
        payload: { plans },
      }).then(() => {
        showSuccessToast(this.$swal, successMessage);
        this.refreshData()
      }).catch(error => {
        showErrorToast(this.$swal, errorMessage);
      });
      this.handleCloseClick()
    },
    handleConfirmDeleteMultiClick() {
      let planIds = []
      this.selecteditems.map((item) => {
        planIds = [...planIds, item.uid]
      })
      let seletedItemsCount = this.seletedItemsCount;
      this.deleteTestPlans({
        swal: this.$swal,
        handle: this.currentAccount.handle,
        projectKey: this.$route.params.key,
        payload: { planIds: planIds },
      }).then(() => {
        showSuccessToast(this.$swal, this.$t("plans.deleteTestPlans", { count: seletedItemsCount }));
        this.refreshData()
      }).catch(error => {
        showErrorToast(this.$swal, this.$t("plans.deleteTestPlans", { count: seletedItemsCount }));
      });
      this.handleCloseClick()
    },
    handleCloseClick() {
      this.showConfirmDialog = false;
      this.selectedPlan = [];
      this.selecteditems = [];
      this.clearSelection = true;
    },
    handleConfirmDialog(type) {
      if (!this.isProjectArchived) {
      this.showConfirmDialog = true;
      switch (type) {
        case 'multi_archive':
          this.dialog.confirmDialogTitle = this.$t('plans.archiveDialog.titleMulti');
          this.dialog.confirmDialogContent = this.$t('plans.archiveDialog.content');
          this.dialog.confirmDialogContentPartTwo = '';
          this.dialog.confirmDialogBtnLabel = this.$t('plans.archiveDialog.btnLabel');
          this.dialog.confirmDialogBtnColor = 'primary';
          this.dialog.confirmDialogName = '';
          this.dialog.confirmType = 'multi_archive';
          break;
        case 'multi_unarchive':
          this.dialog.confirmDialogTitle = this.$t('plans.unArchiveDialog.titleMulti');
          this.dialog.confirmDialogContent = this.$t('plans.unArchiveDialog.content');
          this.dialog.confirmDialogContentPartTwo = '';
          this.dialog.confirmDialogBtnLabel = this.$t('plans.unArchiveDialog.btnLabel');
          this.dialog.confirmDialogBtnColor = 'primary';
          this.dialog.confirmDialogName = '';
          this.dialog.confirmType = 'multi_unarchive';
          break;
        case 'multi_delete':
          this.dialog.confirmDialogTitle = this.$t('plans.deleteDialog.title');
          this.dialog.confirmDialogContent = this.$t('plans.deleteDialog.content');
          this.dialog.confirmDialogContentPartTwo = (this.filter === 'ongoing' ? this.$t('plans.deleteDialog.contentPart2') : '');
          this.dialog.confirmDialogBtnLabel = this.$t('plans.deleteDialog.btnLabel');
          this.dialog.confirmDialogBtnColor = 'danger';
          this.dialog.confirmDialogName = '';
          this.dialog.confirmType = 'multi_delete';
          break;
        case 'archive':
          this.dialog.confirmDialogTitle = this.$t('plans.archiveDialog.title');
          this.dialog.confirmDialogContent = this.$t('plans.archiveDialog.content');
          this.dialog.confirmDialogContentPartTwo = '';
          this.dialog.confirmDialogBtnLabel = this.$t('plans.archiveDialog.btnLabel');
          this.dialog.confirmDialogBtnColor = 'primary';
          this.dialog.confirmDialogName = this.selectedPlan.name;
          this.dialog.confirmType = 'archive';
          break;
        case 'unarchive':
          this.dialog.confirmDialogTitle = this.$t('plans.unArchiveDialog.title');
          this.dialog.confirmDialogContent = this.$t('plans.unArchiveDialog.content');
          this.dialog.confirmDialogContentPartTwo = '';
          this.dialog.confirmDialogBtnLabel = this.$t('plans.unArchiveDialog.btnLabel');
          this.dialog.confirmDialogBtnColor = 'primary';
          this.dialog.confirmDialogName = this.selectedPlan.name;
          this.dialog.confirmType = 'unarchive';
          break;
        case 'delete':
          this.dialog.confirmDialogTitle = this.$t('plans.deleteDialog.title');
          this.dialog.confirmDialogContent = this.$t('plans.deleteDialog.content');
          this.dialog.confirmDialogContentPartTwo = (this.filter === 'ongoing' ? this.$t('plans.deleteDialog.contentPart2') : '');
          this.dialog.confirmDialogBtnLabel = this.$t('plans.deleteDialog.btnLabel');
          this.dialog.confirmDialogBtnColor = 'danger';
          this.dialog.confirmDialogName = '';
          this.dialog.confirmType = 'delete';
          break;
      }
    }
    },
  },
};
</script>

